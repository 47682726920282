<template>
    <div>
        <div class="main-title">上传音频</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">说明</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">最多上传8个</li>
                        <li data-v-69874ad0="">文件名请勿包含特殊字符</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="120px" size="mini">

                <el-form-item label="选择文件：" label-position="left">
                    <Audio accept=".mp3" @success="onSuccess"></Audio>
                </el-form-item>


                <el-form-item label="已上传文件：" label-position="left" class="margin-l-sm">
                    <el-table style="width: 700px" size="mini" highlight-current-row border :data="tableData">
                        <el-table-column prop="name" label="文件名">
                        </el-table-column>
                        <el-table-column prop="format" label="文件类型" width="120">
                        </el-table-column>
                        <el-table-column prop="size" label="文件大小" width="120">
                            <template slot-scope="scope">
                                <span class="state-up">
                                    {{parseFloat((scope.row.size/1024)/1024).toFixed(2) + 'M'}}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Audio from '@/components/audio'
export default {
    name: "addAudio",
    components: { Audio },
    data() {
        return {
            form: {

            },
            uploadSuccessFiles: [],
            fileList: [],
            tableData: []
        }
    },
    methods: {
        ...mapActions('audio', ['addAudio']),
        onSuccess(data) {
            this.add(data)
            this.tableData.push(data)
        },
        async add(audio) {
            const { res_info, data } = await this.addAudio(audio)
            if (res_info !== 'ok') return
            this.$message.success('保存成功!')
        }

    }
}
</script>

<style >
.el-upload-dragger {
    width: 700px;
    height: 120px;
    border: 1px solid #ebebeb;
    background-color: #fafafa;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.el-upload-dragger .el-icon-upload {
    font-size: 40px;
    color: #C0C4CC;
    margin: 10px auto;
    line-height: 40px;
}

.el-table th {
    background: #fafafa;
}

.el-table--mini th {
    padding: 0
}

.upload-demo {
    width: 700px;
}
</style>
